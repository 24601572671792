/* 관리자 사이트 화면 배치 */

.ant-layout {
    overflow-x: auto;
}

.ant-layout-sider {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 100;
}

.ant-layout-sider .ant-menu {
    height: 100%;
}

.ant-layout-header {
    background-color: #fff;
}

.site-layout { margin-left: 250px }

/* 라운딩 */

.ant-btn,
.ant-input,
.ant-picker,
.ant-input-number,
.ant-select-selector { border-radius: 0.25rem !important }

.ant-modal-header,
.ant-modal-content { border-radius: 0.5rem }

/* 모달 */

.ant-modal-body {
    max-height: 650px;
    font-size: 1rem;
    overflow-y: auto;
}

/* 기타 스타일 */

.ant-tabs-nav { border-bottom: none }
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav { margin-bottom: 0 }
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before { border-bottom: none }

.ant-col > .ant-row { margin-bottom: 0 }

.ant-radio-group {
    font-size: inherit;
}