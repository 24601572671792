@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
/* 관리자 사이트 화면 배치 */

.ant-layout {
    overflow-x: auto;
}

.ant-layout-sider {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 100;
}

.ant-layout-sider .ant-menu {
    height: 100%;
}

.ant-layout-header {
    background-color: #fff;
}

.site-layout { margin-left: 250px }

/* 라운딩 */

.ant-btn,
.ant-input,
.ant-picker,
.ant-input-number,
.ant-select-selector { border-radius: 0.25rem !important }

.ant-modal-header,
.ant-modal-content { border-radius: 0.5rem }

/* 모달 */

.ant-modal-body {
    max-height: 650px;
    font-size: 1rem;
    overflow-y: auto;
}

/* 기타 스타일 */

.ant-tabs-nav { border-bottom: none }
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav { margin-bottom: 0 }
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before { border-bottom: none }

.ant-col > .ant-row { margin-bottom: 0 }

.ant-radio-group {
    font-size: inherit;
}
.post-container .image img {
    max-width: 100%;
}

.ck-editor__editable_inline {
    min-height: 400px;
}

.kakao-map .markerbg { background:url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png) no-repeat; }
.kakao-map .marker_1 { background-position: 0 -10px; }
.kakao-map .marker_2 { background-position: 0 -56px; }
.kakao-map .marker_3 { background-position: 0 -102px; }
.kakao-map .marker_4 { background-position: 0 -148px; }
.kakao-map .marker_5 { background-position: 0 -194px; }
.kakao-map .marker_6 { background-position: 0 -240px; }
.kakao-map .marker_7 { background-position: 0 -286px; }
.kakao-map .marker_8 { background-position: 0 -332px; }
.kakao-map .marker_9 { background-position: 0 -378px; }
.kakao-map .marker_10 { background-position: 0 -423px; }
.kakao-map .marker_11 { background-position: 0 -470px; }
.kakao-map .marker_12 { background-position: 0 -516px; }
.kakao-map .marker_13 { background-position: 0 -562px; }
.kakao-map .marker_14 { background-position: 0 -608px; }
.kakao-map .marker_15 { background-position: 0 -654px; }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}
html, body {
    font-family: 'Noto Sans KR', sans-serif;
}
