.post-container .image img {
    max-width: 100%;
}

.ck-editor__editable_inline {
    min-height: 400px;
}

.kakao-map .markerbg { background:url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png) no-repeat; }
.kakao-map .marker_1 { background-position: 0 -10px; }
.kakao-map .marker_2 { background-position: 0 -56px; }
.kakao-map .marker_3 { background-position: 0 -102px; }
.kakao-map .marker_4 { background-position: 0 -148px; }
.kakao-map .marker_5 { background-position: 0 -194px; }
.kakao-map .marker_6 { background-position: 0 -240px; }
.kakao-map .marker_7 { background-position: 0 -286px; }
.kakao-map .marker_8 { background-position: 0 -332px; }
.kakao-map .marker_9 { background-position: 0 -378px; }
.kakao-map .marker_10 { background-position: 0 -423px; }
.kakao-map .marker_11 { background-position: 0 -470px; }
.kakao-map .marker_12 { background-position: 0 -516px; }
.kakao-map .marker_13 { background-position: 0 -562px; }
.kakao-map .marker_14 { background-position: 0 -608px; }
.kakao-map .marker_15 { background-position: 0 -654px; }
